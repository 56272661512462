var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('p',{staticClass:"mb-3"},[_vm._v(" a) Provide the pressure against 1/Volume (1/L). In the table below, enter your experimental data for pressure and volume measurements. Be sure to enter at least six (6) data points to improve the quality of your results. ")])]),_c('v-row',[(!_vm.isMarking)?_c('v-btn',{on:{"click":_vm.addRun}},[_vm._v("Add Run")]):_vm._e()],1),_c('v-row',[_c('v-data-table',{attrs:{"headers":_vm.pressureVolumeColDefs,"items":_vm.inputs.pressureVolume,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(index + 1))])]}},(!_vm.isMarking)?{key:"item.pressure",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"number","hide-details":true,"dense":"","single-line":""},on:{"input":function($event){item.pressure = $event !== '' ? Number($event) : null}},model:{value:(item.pressure),callback:function ($$v) {_vm.$set(item, "pressure", _vm._n($$v))},expression:"item.pressure"}})]}}:null,(!_vm.isMarking)?{key:"item.oneOverVolume",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"number","hide-details":true,"dense":"","single-line":""},on:{"input":function($event){item.oneOverVolume = $event !== '' ? Number($event) : null}},model:{value:(item.oneOverVolume),callback:function ($$v) {_vm.$set(item, "oneOverVolume", _vm._n($$v))},expression:"item.oneOverVolume"}})]}}:null,(!_vm.isMarking)?{key:"item.id",fn:function(ref){
var index = ref.index;
return [_c('v-icon',{on:{"click":function($event){return _vm.removeRun(index)}}},[_vm._v(_vm._s(_vm.$i('common.close')))])]}}:null],null,true)})],1),_c('v-row',[_c('apex-chart',{staticClass:"flex-grow-1",attrs:{"options":_vm.apexOptions,"series":_vm.apexSeries,"height":"400"}})],1),_c('v-row',[_c('p',{staticClass:"mb-3"},[_vm._v(" b) Using spreadsheet software, find the line of best fit for your data and enter the slope and intercept below. ")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"type":"number","readonly":_vm.isMarking,"label":"Slope"},on:{"input":function($event){_vm.inputs.slope = $event !== '' ? Number($event) : null}},model:{value:(_vm.inputs.slope),callback:function ($$v) {_vm.$set(_vm.inputs, "slope", _vm._n($$v))},expression:"inputs.slope"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"type":"number","readonly":_vm.isMarking,"label":"Intercept"},on:{"input":function($event){_vm.inputs.intercept = $event !== '' ? Number($event) : null}},model:{value:(_vm.inputs.intercept),callback:function ($$v) {_vm.$set(_vm.inputs, "intercept", _vm._n($$v))},expression:"inputs.intercept"}})],1)],1),_c('p',[_c('stemble-latex',{attrs:{"content":_vm.boylesLaw}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }