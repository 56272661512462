

















































































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AssistedGradingWritten from '@/common/components/AssistedGradingWritten.vue';
import Task from '@/tasks/models/Task';
import {TaskType} from '@/tasks/types/TaskType';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import VueApexCharts from 'vue-apexcharts/dist/vue-apexcharts';
import {inject} from '@/container';
import {UseConcordiaExperiment1SlopeIntercept} from '@/tasks/composables/UseConcordiaExperiment1SlopeIntercept';

export default defineComponent({
  name: 'ConcordiaChem206Experiment1Part1',
  components: {StembleLatex, AssistedGradingWritten, ApexChart: VueApexCharts},
  mixins: [DynamicQuestionMixin()],
  props: {
    task: {
      type: Object as PropType<Task<TaskType.DynamicQuestion>>,
      required: true,
    },
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props) {
    const {inputs, colDefs, equation, apexSeries, apexOptions, addRun, removeRun} = inject(
      UseConcordiaExperiment1SlopeIntercept,
      {pressure: 'Pressure (atm)', oneOverVolume: '1/Volume (1/L)'},
      'pressure',
      'oneOverVolume',
      'pressureVolume',
      props.isMarking,
      'P',
      '\\left(\\frac{1}{V}\\right)',
      0.2,
      5,
      0,
      1200
    );

    return {
      pressureVolumeColDefs: colDefs,
      boylesLaw: equation,
      inputs,
      apexOptions,
      apexSeries,
      addRun,
      removeRun,
    };
  },
});
